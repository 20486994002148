<!-- eslint-disable vue/no-template-key -->
<template>
  <div class="payment-methods-container">
    <div class="w-full">
      <div class="headline-m mb-4">
        วิธีการชำระเงิน
      </div>

      <div class="parent-container">
        <template v-if="isDeposit">
          <div
            :class="[
              'item-container',
              { '-is-selected': isSelectedDepositList },
            ]"
          >
            <div
              class="item"
              @click="onSelectPaymentMethod(depositList[0])"
            >
              <span class="checkmark"></span>
              <div>
                ชำระเงินแบบมัดจำ
              </div>
              <div></div>
            </div>
            <div v-if="isSelectedDepositList" class="item-desc">
              <template v-for="item in depositList">
                <CheckoutPaymentMethodItem
                  :key="item.id"
                  data-pptr="payment_type_id"
                  :hasError="isErrorRequirePayment"
                  :name="item.title"
                  :label="item.short_description"
                  :image="iconBank[item.id]"
                  :selected="paymentId === item.id"
                  @click="onSelectPaymentMethod(item)"
                />

                <div
                  v-if="
                    paymentId === item.id &&
                      JSON.parse(item.dropdown) &&
                      JSON.parse(item.dropdown).length > 0
                  "
                  :key="`items-${item.id}`"
                  class="installment-plan-container"
                >
                  <label class="text-sm">
                    เลือกวิธีการผ่อนชำระ
                    <span class="text-red">*</span>
                  </label>
                  <v-select
                    v-model="installmentPlan"
                    :dense="true"
                    :items="JSON.parse(item.dropdown)"
                    :color="colorBnn['color-bnn']"
                    class="text-sm"
                    background-color="white"
                    item-text="terms"
                    item-value="item"
                    placeholder="เลือกวิธีการผ่อนชำระ"
                    ref="items"
                    outlined
                    hide-details
                    return-object
                    @change="handleUpdateInstallmentPlan"
                  >
                    <template slot="selection" slot-scope="data">
                      ผ่อนชำระ {{ data.item.terms }} เดือน ดอกเบี้ย
                      {{ Number(data.item.interest) }}%
                    </template>
                    <template slot="item" slot-scope="data">
                      ผ่อนชำระ {{ data.item.terms }} เดือน ดอกเบี้ย
                      {{ Number(data.item.interest) }}%
                    </template>
                  </v-select>
                </div>
              </template>
            </div>
          </div>

          <template v-if="appListDeposit.length > 0">
            <div
              :class="[
                'item-container',
                { '-is-selected': isSelectedAppListDeposit },
              ]"
            >
              <div
                class="item"
                @click="onSelectPaymentMethod(appListDeposit[0])"
              >
                <span class="checkmark"></span>
                <div>
                  ชำระผ่านแอพพลิเคชั่น
                </div>
                <div></div>
              </div>
              <div v-if="isSelectedAppListDeposit" class="item-desc">
                <template v-for="item in appListDeposit">
                  <CheckoutPaymentMethodItem
                    :key="item.id"
                    :hasError="isErrorRequirePayment"
                    :name="item.title"
                    :image="iconBank[item.id]"
                    :selected="paymentId === item.id"
                    :label="item.short_description"
                    data-pptr="payment_type_id"
                    @click="onSelectPaymentMethod(item)"
                  />
                  <div
                    v-if="
                      paymentId === item.id &&
                        JSON.parse(item.dropdown) &&
                        JSON.parse(item.dropdown).length > 0
                    "
                    :key="`items-${item.id}`"
                    class="installment-plan-container"
                  >
                    <label class="text-sm">
                      เลือกวิธีการผ่อนชำระ
                      <span class="text-red">*</span>
                    </label>
                    <v-select
                      v-model="installmentPlan"
                      :dense="true"
                      :items="JSON.parse(item.dropdown)"
                      :color="colorBnn['color-bnn']"
                      class="text-sm"
                      background-color="white"
                      item-text="terms"
                      item-value="item"
                      placeholder="เลือกวิธีการผ่อนชำระ"
                      ref="items"
                      outlined
                      hide-details
                      return-object
                      @change="handleUpdateInstallmentPlan"
                    >
                      <template slot="selection" slot-scope="data">
                        ผ่อนชำระ {{ data.item.terms }} เดือน ดอกเบี้ย
                        {{ Number(data.item.interest) }}%
                      </template>
                      <template slot="item" slot-scope="data">
                        ผ่อนชำระ {{ data.item.terms }} เดือน ดอกเบี้ย
                        {{ Number(data.item.interest) }}%
                      </template>
                    </v-select>
                  </div>
                </template>
              </div>
            </div>
          </template>
        </template>

        <template v-else>
          <template v-if="isShowPayAtStoreMethod">
            <div
              :class="[
                'item-container pay-at-store',
                { '-is-selected': isSelectedPayAtStoreList },
              ]"
            >
              <div
                class="item"
                @click="onSelectPaymentMethod(payAtStoreList[0])"
              >
                <span class="checkmark"></span>
                <div>
                  ชำระเงินที่สาขา
                </div>
                <div></div>
              </div>
              <div
                v-if="
                  isSelectedPayAtStoreList &&
                    payAtStoreList.length > 1
                "
                class="item-desc"
              >
                <template v-for="item in payAtStoreList">
                  <CheckoutPaymentMethodItem
                    :key="item.id"
                    :hasError="isErrorRequirePayment"
                    :name="item.title"
                    :image="iconBank[item.id]"
                    :selected="paymentId === item.id"
                    :label="item.short_description"
                    data-pptr="payment_type_id"
                    @click="onSelectPaymentMethod(item)"
                  />
                  <div
                    v-if="
                      paymentId === item.id &&
                        JSON.parse(item.dropdown) &&
                        JSON.parse(item.dropdown).length > 0
                    "
                    :key="`items-${item.id}`"
                    class="installment-plan-container"
                  >
                    <label class="text-sm">
                      เลือกวิธีการผ่อนชำระ
                      <span class="text-red">*</span>
                    </label>
                    <v-select
                      v-model="installmentPlan"
                      :dense="true"
                      :items="JSON.parse(item.dropdown)"
                      :color="colorBnn['color-bnn']"
                      class="text-sm"
                      background-color="white"
                      item-text="terms"
                      item-value="item"
                      placeholder="เลือกวิธีการผ่อนชำระ"
                      ref="items"
                      outlined
                      hide-details
                      return-object
                      @change="handleUpdateInstallmentPlan"
                    >
                      <template slot="selection" slot-scope="data">
                        ผ่อนชำระ {{ data.item.terms }} เดือน ดอกเบี้ย
                        {{ Number(data.item.interest) }}%
                      </template>
                      <template slot="item" slot-scope="data">
                        ผ่อนชำระ {{ data.item.terms }} เดือน ดอกเบี้ย
                        {{ Number(data.item.interest) }}%
                      </template>
                    </v-select>
                  </div>
                </template>
              </div>
            </div>
          </template>

          <template v-else>
            <template v-if="fullPaymentList">
              <div
                :class="[
                  'item-container',
                  { '-is-selected': isSelectedFullPaymentList },
                ]"
              >
                <div
                  class="item"
                  @click="onSelectPaymentMethod(fullPaymentList[0])"
                >
                  <span class="checkmark"></span>
                  <div>
                    ชำระเงินแบบเต็มจำนวน
                  </div>
                </div>
                <div
                  v-if="isSelectedFullPaymentList"
                  class="item-desc"
                >
                  <template v-for="item in fullPaymentList">
                    <CheckoutPaymentMethodItem
                      :key="item.id"
                      :hasError="isErrorRequirePayment"
                      :name="item.title"
                      :image="iconBank[item.id]"
                      :selected="paymentId === item.id"
                      :label="item.short_description"
                      data-pptr="payment_type_id"
                      @click="onSelectPaymentMethod(item)"
                    />

                    <div
                      v-if="
                        paymentId === item.id &&
                          JSON.parse(item.dropdown) &&
                          JSON.parse(item.dropdown).length > 0
                      "
                      :key="`items-${item.id}`"
                      class="installment-plan-container"
                    >
                      <label class="text-sm">
                        เลือกวิธีการผ่อนชำระ
                        <span class="text-red">*</span>
                      </label>
                      <v-select
                        v-model="installmentPlan"
                        :dense="true"
                        :items="JSON.parse(item.dropdown)"
                        :color="colorBnn['color-bnn']"
                        class="text-sm"
                        background-color="white"
                        item-text="terms"
                        item-value="item"
                        placeholder="เลือกวิธีการผ่อนชำระ"
                        ref="items"
                        outlined
                        hide-details
                        return-object
                        @change="handleUpdateInstallmentPlan"
                      >
                        <template slot="selection" slot-scope="data">
                          ผ่อนชำระ {{ data.item.terms }} เดือน
                          ดอกเบี้ย {{ Number(data.item.interest) }}%
                        </template>
                        <template slot="item" slot-scope="data">
                          ผ่อนชำระ {{ data.item.terms }} เดือน
                          ดอกเบี้ย {{ Number(data.item.interest) }}%
                        </template>
                      </v-select>
                    </div>
                  </template>
                </div>
              </div>
            </template>

            <template v-if="installmentList">
              <div
                :class="[
                  'item-container',
                  { '-is-selected': isSelectedInstallmentList },
                ]"
              >
                <div
                  class="item"
                  @click="onSelectPaymentMethod(installmentList[0])"
                >
                  <span class="checkmark"></span>
                  <div>
                    ผ่อนชำระด้วยบัตรเครดิต
                  </div>
                  <div></div>
                </div>
                <div
                  v-if="isSelectedInstallmentList"
                  class="item-desc"
                >
                  <template v-for="item in installmentList">
                    <CheckoutPaymentMethodItem
                      :key="`item-${item.id}`"
                      :hasError="isErrorRequirePayment"
                      :name="item.title"
                      :image="iconBank[item.id]"
                      :selected="paymentId === item.id"
                      :label="item.short_description"
                      data-pptr="payment_type_id"
                      @click="onSelectPaymentMethod(item)"
                    />

                    <div
                      v-if="
                        paymentId === item.id &&
                          JSON.parse(item.dropdown) &&
                          JSON.parse(item.dropdown).length > 0
                      "
                      :key="`items-${item.id}`"
                      class="installment-plan-container"
                    >
                      <label class="text-sm">
                        เลือกวิธีการผ่อนชำระ
                        <span class="text-red">*</span>
                      </label>
                      <v-select
                        v-model="installmentPlan"
                        :dense="true"
                        :items="JSON.parse(item.dropdown)"
                        :color="colorBnn['color-bnn']"
                        class="text-sm"
                        background-color="white"
                        item-text="terms"
                        item-value="item"
                        placeholder="เลือกวิธีการผ่อนชำระ"
                        ref="items"
                        outlined
                        hide-details
                        return-object
                        @change="handleUpdateInstallmentPlan"
                      >
                        <template slot="selection" slot-scope="data">
                          ผ่อนชำระ {{ data.item.terms }} เดือน
                          ดอกเบี้ย {{ Number(data.item.interest) }}%
                        </template>
                        <template slot="item" slot-scope="data">
                          ผ่อนชำระ {{ data.item.terms }} เดือน
                          ดอกเบี้ย {{ Number(data.item.interest) }}%
                        </template>
                      </v-select>
                    </div>
                  </template>
                </div>
              </div>
            </template>

            <template v-if="appList.length > 0">
              <div
                :class="[
                  'item-container',
                  {
                    '-is-selected': isSelectedAppList,
                  },
                ]"
              >
                <div
                  class="item"
                  @click="onSelectPaymentMethod(appList[0])"
                >
                  <span class="checkmark"></span>
                  <div>
                    ชำระผ่านแอพพลิเคชั่น
                  </div>
                  <div></div>
                </div>
                <div v-if="isSelectedAppList" class="item-desc">
                  <template v-for="item in appList">
                    <CheckoutPaymentMethodItem
                      :key="item.id"
                      :hasError="isErrorRequirePayment"
                      :name="item.title"
                      :image="iconBank[item.id]"
                      :selected="paymentId === item.id"
                      :label="item.short_description"
                      data-pptr="payment_type_id"
                      @click="onSelectPaymentMethod(item)"
                    />

                    <div
                      v-if="
                        paymentId === item.id &&
                          JSON.parse(item.dropdown) &&
                          JSON.parse(item.dropdown).length > 0
                      "
                      :key="`items-${item.id}`"
                      class="installment-plan-container"
                    >
                      <label class="text-sm">
                        เลือกวิธีการผ่อนชำระ
                        <span class="text-red">*</span>
                      </label>
                      <v-select
                        v-model="installmentPlan"
                        :dense="true"
                        :items="JSON.parse(item.dropdown)"
                        :color="colorBnn['color-bnn']"
                        class="text-sm"
                        background-color="white"
                        item-text="terms"
                        item-value="item"
                        placeholder="เลือกวิธีการผ่อนชำระ"
                        ref="items"
                        outlined
                        hide-details
                        return-object
                        @change="handleUpdateInstallmentPlan"
                      >
                        <template slot="selection" slot-scope="data">
                          ผ่อนชำระ {{ data.item.terms }} เดือน
                          ดอกเบี้ย {{ Number(data.item.interest) }}%
                        </template>
                        <template slot="item" slot-scope="data">
                          ผ่อนชำระ {{ data.item.terms }} เดือน
                          ดอกเบี้ย {{ Number(data.item.interest) }}%
                        </template>
                      </v-select>
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </template>
        </template>
      </div>
    </div>

    <div v-if="isVerifyRecaptcha">
      <Recaptcha @validate="validate" />
    </div>

    <div ref="beforeCheckoutAction"></div>

    <!-- <div class="payment-label font-bold" v-if="isDeposit">
      *หากชำระเงินค่ามัดจำสินค้าเรียบร้อยแล้ว
      ท่านสามารถชำระยอดที่เหลือที่สาขาที่เลือก ตามที่ระบุใน Email หรือ
      SMS , Line
    </div> -->

    <!-- Summary for mobile size -->
    <div class="d-block d-md-none">
      <slot></slot>
    </div>

    <div class="payment-methods-actions-container">
      <v-btn
        depressed
        class="btn-link dp-none dp-md-block"
        text
        :color="colorBnn['color-bnn']"
        @click="goBack()"
      >
        <v-icon>
          mdi-chevron-left
        </v-icon>
        กลับสู่หน้าสินค้า
      </v-btn>

      <v-btn
        v-if="!isSoldOut"
        depressed
        :dark="true"
        :color="colorBnn['color-bnn']"
        :disabled="disabledButton"
        :loading="loading"
        @click="checkValid ? onSubmit() : false"
      >
        ตรวจสอบคำสั่งซื้อและชำระเงิน
      </v-btn>

      <Button
        v-else
        disabled
        :loading="loading"
        width="100%"
        depressed
        color="color-bnn-opacity-90"
        title="สินค้าหมด"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Button from '@/components/bnn/Button'
// import routePage from '@/config/routePage'
import {
  P_KBANK_DEPOSIT,
  P_KRUNGSRI_DEPOSIT,
  P_KBANK_INSTALLMENT,
  P_KBANK_FULL,
  P_2C2P_DEPOSIT,
  P_KRUNGSRI_FULL,
  P_2C2P_FULL,
  P_KRUNGSRI_INSTALLMENT,
  P_2C2P_INSTALLMENT,
  P_2C2P_UOB_INSTALLMENT,
  P_KRUNGSRI_FIRST_CHOICE_INSTALLMENT,
  P_2C2P_CITYBANK_INSTALLMENT,
  P_SCBEASY_FULL,
  P_SCBEASY_CREDIT_FULL,
  P_SCBEASY_DEPOSIT,
  P_SCBEASY_CREDIT_DEPOSIT,
  P_SCBEASY_INSTALLMENT,
  P_KBANK_QR_FULL,
  P_KBANK_QR_DEPOSIT,
  P_PAY_AT_STORE,
} from '@/config/payment.json'
import iconBank from '@/config/iconBank'
import Recaptcha from '@/components/Recaptcha'
// import CartSummary from '@/components/bnn/CartSummary'
import branchTypes from '@/config/branchTypes'
import cartSummary from '@/mixins/cartSummary'
// import SummaryCart from '@/components/bnn/SummaryCart'
import { STOCK_CARD_TYPE } from '@/config/stockCard'
import colorBnn from '@/config/color'

export default {
  name: 'CheckoutPaymentMethod',

  mixins: [cartSummary],

  props: {
    loading: Boolean,
    isErrorRequirePayment: Boolean,
  },

  components: {
    CheckoutPaymentMethodItem: () =>
      import('@/components/studio7/CheckoutPaymentMethodItem'),
    Recaptcha,
    Button,
    // SummaryCart,
    // CartSummary,
  },

  computed: {
    ...mapState({
      branchType: state => state.branchStore.branchType,
      campaign: state => state.campaign.current,
      paymentTypeData: state => state.paymentType.data,
      paymentId: state => state.paymentType.paymentId,
      coupon: state => state.cart.coupon,
      showSummary: state => state.cart.showSummary,
      cartSummaryData: state => state.cart.cartSummaryData,
    }),
    ...mapGetters({
      //   haveDeposit: 'cart/haveDeposit',
      //   priceDeposit: 'cart/priceDeposit',
      bundles: 'model/getBundles',
      additionalProduct: 'model/getAdditionalProduct',
      appleCare: 'model/getAppleCare',
      productItem: 'cart/productItem',
      priceSummary: 'cart/priceSummary',
      isSoldOut: 'model/getSoldOut',
      deliveryItems: 'cart/deliveryItems',
      bundleItems: 'cart/bundleItems',
      //   cartItems: 'cart/cartItems',
    }),

    isDeposit() {
      return this.branchType === this.branchTypes.BRANCH_TYPE_DEPOSIT
    },

    netPriceSummary() {
      return this.cartSummaryData?.total_price ?? 0
    },

    depositList() {
      return (
        this.paymentTypeData?.filter(
          i => i?.type === 'deposit',
          // i?.name?.toUpperCase().indexOf('SCB') === -1,
        ) ?? []
      )
    },

    isSelectedDepositList() {
      return this.depositList.some(item => item.id === this.paymentId)
    },

    fullPaymentList() {
      return (
        this.paymentTypeData?.filter(
          i => i?.type === 'full-payment',
          // i?.name?.toUpperCase().indexOf('SCB') === -1,
        ) ?? []
      )
    },

    isSelectedFullPaymentList() {
      return this.fullPaymentList.some(
        item => item.id === this.paymentId,
      )
    },

    payAtStoreList() {
      return (
        this.paymentTypeData?.filter(
          i => i?.type === 'pay-at-store',
          // i?.name?.toUpperCase().indexOf('SCB') === -1,
        ) ?? []
      )
    },

    isSelectedPayAtStoreList() {
      return this.payAtStoreList.some(
        item => item.id === this.paymentId,
      )
    },

    installmentList() {
      return (
        this.paymentTypeData?.filter(
          i => i?.type === 'installment',
          // i?.name?.toUpperCase().indexOf('SCB') === -1,
        ) ?? []
      )
    },

    isSelectedInstallmentList() {
      return this.installmentList.some(
        item => item.id === this.paymentId,
      )
    },

    appList() {
      // TODO: Deprecate this
      // return (
      //   this.paymentTypeData?.filter(
      //     i =>
      //       i?.type !== 'deposit' &&
      //       i?.name?.toUpperCase().indexOf('SCB') > -1,
      //   ) ?? []
      // )
      return []
    },

    isSelectedAppList() {
      return this.appList.some(item => item.id === this.paymentId)
    },

    appListDeposit() {
      // TODO: Deprecate this
      // return (
      //   this.paymentTypeData?.filter(
      //     i =>
      //       i?.type === 'deposit' &&
      //       i?.name?.toUpperCase().indexOf('SCB') > -1,
      //   ) ?? []
      // )
      return []
    },

    isSelectedAppListDeposit() {
      return this.appListDeposit.some(
        item => item.id === this.paymentId,
      )
    },

    shipDate() {
      return this.campaign?.data?.shipdate
    },

    campaignId() {
      return this.campaign?.data?.id ?? ''
    },

    disabledButton() {
      if (this.isVerifyRecaptcha) {
        return !this.checkAgree || !this.validateRecaptcha
      }
      return !this.checkAgree
    },

    checkValid() {
      if (this.isVerifyRecaptcha) {
        return this.checkAgree && this.validateRecaptcha
      }
      return this.checkAgree
    },

    isVerifyRecaptcha() {
      return this.campaign?.data?.is_verify_recaptcha === 1
    },

    checkoutActionClass() {
      // TODO: Not sticky
      // get position div class cart-ship-wrapper
      // return {
      //   'checkout-action-fixed':
      //     this.scrollY > this.positionCheckoutAction,
      // }
      return {}
    },

    isShowPayAtStoreMethod() {
      const { t } = this.$route.query

      return (
        this.payAtStoreList.length > 0 &&
        (t === STOCK_CARD_TYPE.STORE || t === STOCK_CARD_TYPE.QR)
      )
    },
  },

  async mounted() {
    // await this.$store.dispatch(
    //   'paymentType/getPaymentType',
    //   this.campaignId,
    // )
    window.addEventListener('scroll', this.handleScroll)
  },

  data() {
    return {
      colorBnn,
      branchTypes,
      validateRecaptcha: false,
      checkAgree: true,
      isShowTerm: false,
      P_KBANK_FULL: P_KBANK_FULL, //
      P_KBANK_INSTALLMENT: P_KBANK_INSTALLMENT, //
      P_KBANK_DEPOSIT: P_KBANK_DEPOSIT, //
      P_KRUNGSRI_DEPOSIT: P_KRUNGSRI_DEPOSIT, //
      P_2C2P_DEPOSIT: P_2C2P_DEPOSIT, //
      P_KRUNGSRI_FULL: P_KRUNGSRI_FULL, //
      P_2C2P_FULL: P_2C2P_FULL, //
      P_KRUNGSRI_INSTALLMENT: P_KRUNGSRI_INSTALLMENT, //
      P_2C2P_INSTALLMENT: P_2C2P_INSTALLMENT, //
      P_2C2P_UOB_INSTALLMENT: P_2C2P_UOB_INSTALLMENT, //
      P_KRUNGSRI_FIRST_CHOICE_INSTALLMENT: P_KRUNGSRI_FIRST_CHOICE_INSTALLMENT, //
      P_2C2P_CITYBANK_INSTALLMENT: P_2C2P_CITYBANK_INSTALLMENT, //
      P_SCBEASY_FULL: P_SCBEASY_FULL, //
      P_SCBEASY_CREDIT_FULL: P_SCBEASY_CREDIT_FULL, //
      P_SCBEASY_DEPOSIT: P_SCBEASY_DEPOSIT, //
      P_SCBEASY_CREDIT_DEPOSIT: P_SCBEASY_CREDIT_DEPOSIT, //
      P_SCBEASY_INSTALLMENT: P_SCBEASY_INSTALLMENT, //
      P_KBANK_QR_FULL: P_KBANK_QR_FULL,
      P_KBANK_QR_DEPOSIT: P_KBANK_QR_DEPOSIT,
      P_PAY_AT_STORE: P_PAY_AT_STORE,
      iconBank,
      scrollY,
      positionCheckoutAction: 0,
      installmentPlan: null,
    }
  },

  watch: {
    isDeposit() {
      this.autoSelectPayment()
    },
  },

  methods: {
    validate(success) {
      this.validateRecaptcha = success
    },

    onSubmit() {
      if (!this.loading) this.$emit('onSubmit')
    },

    onSelectPaymentMethod(event) {
      this.$store.commit('paymentType/setPaymentId', event.id)
      this.$emit('onSelectPaymentMethod', event.id)

      let plan = event.dropdown ? JSON.parse(event.dropdown) : null
      if (plan) {
        this.$store.commit(
          'paymentType/setPaymentInstallmentPlan',
          plan?.[0],
        )
        this.installmentPlan = plan?.[0]
      } else {
        this.$store.commit(
          'paymentType/setPaymentInstallmentPlan',
          null,
        )
        this.installmentPlan = null
      }

      this.checkSummary()
      this.$forceUpdate()
    },

    goBack() {
      this.$store.commit('cart/resetCouponCode')
      let backToPage =
        this.additionalProduct.length > 0
          ? 'CustomerAlsoBought'
          : 'ProductDetail'

      this.$router
        .push({
          name: backToPage,
          params: { slug: this.$route.params?.slug },
        })
        .catch(() => true)
    },

    autoSelectPayment() {
      if (this.isDeposit) {
        this.onSelectPaymentMethod(
          this.paymentTypeData?.filter(i => i.type === 'deposit')?.[0]
            ?.id,
        )
      } else {
        if (this.isShowPayAtStoreMethod) {
          this.onSelectPaymentMethod(
            this.paymentTypeData?.filter(
              i => i.type === 'pay-at-store',
            )?.[0]?.id,
          )
        } else {
          this.onSelectPaymentMethod(
            this.paymentTypeData?.filter(
              i => i.type !== 'deposit',
            )?.[0]?.id,
          )
        }
      }
    },

    handleScroll() {
      const checkoutActionHeight = document.querySelector(
        '.checkout-action',
      )?.offsetHeight
      this.scrollY =
        window.scrollY + window.innerHeight - checkoutActionHeight
      this.positionCheckoutAction = this.$refs.beforeCheckoutAction?.offsetTop
    },

    handleUpdateInstallmentPlan(value) {
      this.$store.commit(
        'paymentType/setPaymentInstallmentPlan',
        value,
      )
    },
  },
}
</script>

<style lang="stylus" scoped>
.payment-methods-container {
    display flex
    flex-direction column
    gap 16px

    .parent-container {
        .item-container {

            &:not(:first-of-type):not(:last-of-type){

            }

            &:not(.-is-selected):not(:last-of-type) {
                .item {
                    border-bottom: 0;
                }
            }

            &:first-of-type {
                .item {
                    border-radius: 8px 8px 0 0;
                }
            }

            &:last-child:not(:first-child) {
                > .item {
                    border-radius: 0 0 8px 8px;
                }

                &.-is-selected {
                    > .item {
                        border-radius: 0;
                    }

                    .item-desc {
                        border-radius: 0 0 8px 8px;
                    }
                }
            }

            &:first-of-type:last-child {
                .item-desc {
                    border-radius: 0 0 8px 8px;
                }
            }

            &.-is-selected {
                .item {
                    background-color: $color-fill-radio-button;
                    border: 1.2px solid $color-bnn;

                    .checkmark {
                        border: 5.5px solid $color-bnn;
                    }
                }
            }

            &not(:last-child):not(:first-child) {
                .item {
                    border-top 0
                }
            }

            &.-is-selected:not(:last-child){
                .item-desc {
                    border-bottom: none
                    border-top: none
                }
            }

            &.pay-at-store {
                .item {
                    border-radius: 8px;
                }
            }
        }
    }


    .item {
        display: flex;
        align-items: center;
        gap: 11px;
        padding: 11px;

        border: 1.2px solid $color-dapp-gray-3;
        cursor: pointer;

        &.-is-disabled {
            opacity: 0.4;
            cursor: not-allowed;
            pointer-events: none;
        }

        .item-label {
            flex: 1;

            > .label {
                font-weight: 500;
                color: rgba(#000000, 0.70);
            }

            > .description-s {

                font-size: 12px;
                font-weight: 600;
                line-height: 1.3;
                color: rgba(#000000, 0.45);
            }
        }

        .item-icon {
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                object-fit: contain;
                width: 100%;
                height: 100%;
            }
        }

        .checkmark {
            width 16px;
            height 16px;
            border-radius: 50%;
            border: 1px solid #d2d2d7;
            transition: border .1s;
        }

    }

    .base-radio-button {
        position: relative;
        display: block;
        cursor: pointer;
        user-select: none;

        &.-is-disabled {
            cursor: not-allowed;

            > .label {
                color: $color-absolute-black-45;
            }
        }

        // Hide the browser's default radio
        input {
            display: none;
            opacity: 0;
            width: 0;
            height: 0;
            cursor: pointer;
        }

        // Create a custom radio
        .checkmark {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            height:  16px;
            width: 16px;

            display: flex;
            align-items: center;
            justify-content: center;

            background-color: transparent;
            border-radius: 50%;
            border: 1px solid $color-grey83;
            transition: border 0.1s;
        }

        & input:checked ~ .checkmark {
            border: rem(5.5) solid $color-bnn;
        }

        & input:focus ~ .checkmark {
            box-shadow: 0 0 0 rem(3) $color-functionality-secondary;
        }


        & input:not(:checked):disabled ~ .checkmark {
            border: 2px solid $color-grey-light-50;
        }

        & input:checked:disabled ~ .label,
        & input:not(:checked):disabled ~ .label {
            color: $color-grey-light-50;
        }


        & input:checked:disabled ~ .checkmark {
            border: rem(7) solid $color-grey-light-50;
        }
    }

    .item-desc {
        background-color rgba(0, 0, 0, 0.043)
        border 1px solid rgb(222, 222, 222)
        box-sizing border-box
        color rgb(0, 0, 0)
        display block
        font-size 14px
        font-weight 400
        letter-spacing normal
        line-height 21px
        padding 17px

        width 100%
        display grid
        grid-template-columns: repeat(2, 1fr)
        gap: 12px 16px

        @media screen and (min-width: 1024px) {
            grid-template-columns: repeat(3, 1fr)
        }

        @media screen and (min-width: 1280px) {
            grid-template-columns: repeat(4, 1fr)
        }
    }

    .installment-plan-container {
        grid-column span 2
        order: 1

        @media screen and (min-width: 1024px) {
            grid-column span 3
        }

        @media screen and (min-width: 1280px) {
            grid-column span 4
        }
    }
}

.section-summary {
    width 100%
    max-width: 100dvw

    display flex;
    flex-direction: column;
    gap: 32px;
}

.payment-methods-actions-container {
    display flex;
    justify-content: center;
    align-items: center;
    gap: 16px;

    @media screen and (min-width: 768px) {
        justify-content: space-between;
    }
}
</style>
